import Image from "next/image";
import classes from "./ProductGallery.module.scss";
import { useEffect, useState } from "react";

import imageUrlBuilder from '@sanity/image-url'
import sanityClient from '@/services/sanityClient'

const ProductGallery = ({ product, fields: { galleryLength } }) => {
  const [imagesLoaded, setImagesLoaded] = useState(0)
  let galleryImages = product?.imagesFromSanity

  const builder = imageUrlBuilder(sanityClient)
  function urlFor(source) {
      return builder.image(source)
  }
  
  useEffect(() => {
    if (imagesLoaded === galleryImages.length) {
      const galleryItems = document.querySelectorAll(`.${classes["product-gallery__image"]}`);
      galleryItems.forEach((item, index) => {
        if (index % 2 === 0) {
          item.style.gridRowEnd = "span 2";
        }
      })
    }
  }, [imagesLoaded])

  return (
    <section className={classes["product-gallery"]}>
      {galleryImages.slice(1).filter((_, index) => (index + 1) <= galleryLength || index !== 0).map((image, index) => (
        <span key={`image-gallery-${index}`} className={classes["product-gallery__image"]}>
          <Image src={urlFor(image.productImage.asset.url).width(500).height(500).focalPoint(image?.productImage?.hotspot?.x || 0.5, image?.productImage?.hotspot?.y || 0.5).crop('focalpoint').fit('crop').url()} alt={image.alt} layout="fill" onLoad={() => setImagesLoaded(imagesLoaded + 1)}/>
        </span>
      ))}
    </section>
  )
};

export default ProductGallery;
