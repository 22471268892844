import classes from './ProductHeroGiftForm.module.scss'

const ProductHeroGiftForm = ({ onCheck, checked, onChangeInput, recipientEmail, recipientName }) => {
  return (
    <div className={classes['product-hero-gift']}>
      <label htmlFor="product-quantity" className={classes['product-hero-gift__checkbox']}>
        <input
          type="checkbox"
          id="product-quantity"
          onChange={onCheck}
          checked={checked}
        />
        This is a gift
      </label>
      {checked && (
        <div className={classes["product-hero-gift--form"]}>
          <input
            className={classes["product-hero-gift--email"]}
            placeholder="recipient@email.com"
            type="email"
            id="email"
            name="recipient_email"
            onChange={onChangeInput}
            value={recipientEmail}
            required
          />
          <input
            className={classes["product-hero-gift--name"]}
            placeholder="Recipient Name"
            type="text"
            id="name"
            name="recipient_name"
            onChange={onChangeInput}
            value={recipientName}
            required
          />
        </div>
      )}
    </div>
  )
}

export default ProductHeroGiftForm
